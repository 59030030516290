import React from 'react';

import './style.scss';

const textArray = ['Ocean Beach', 'Fair Harbor', 'Dunewood', 'Kismet', 'Atlantique', 'Ocean Bay Park', 'Seaview', 'Saltaire'];

export default class BeachText extends React.Component {
  constructor(props) {
    super(props);
    this.beach = props.beach || 'Ocean Beach';
    this.rotate = props.rotate || false;
    this.state = {
      textIdx: 0,
    };
  }

  componentWillMount() {
    if (this.rotate) {
      this.timeout = setInterval(() => {
        let currentIdx = this.state.textIdx;
        this.setState({ textIdx: currentIdx += 1 });
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let { beach } = this;
    if (this.rotate) {
      beach = textArray[this.state.textIdx % textArray.length];
    }

    return (
      <div className="beach-frame">
        <svg className={`come-visit-svg ${this.props.mode || 'light'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 211.16 50">
          <path className="come-visit-line" fill="none" stroke="black" strokeWidth="2" d="M175.16 5.57 205.16 5.57 205.16 45 6 45 6 5.57 36 5.57" />
          <text x="105.6" y="35" className="beach-name" textAnchor="middle">
            { beach }
          </text>
          <text x="105.6" y="10" className="come-visit-text" textAnchor="middle">
            Come Visit Beautiful
          </text>
        </svg>
      </div>
    );
  }
}
