import React from 'react';
import { Link } from '@reach/router';
import Headroom from 'react-headroom';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Icon from '../Icon';

import './style.scss';
import logo from '../../assets/img/ferryWhite@2x.png';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <Headroom disableInlineStyles disable={this.state.width < 992}>
        <Navbar color="faded" dark expand="lg" className="nav-bar d-print-none">
          {/* <div className="safe-bg" /> */}
          <div className="container">
            <NavbarBrand to="/" tag={Link}>
              <img src={logo} alt="Fire Island Ferries" />
            </NavbarBrand>
            {/* <NavbarToggler onClick={this.toggle} /> */}
            <button className="navbar-toggler" onClick={this.toggle}>
              {this.state.isOpen ? (
                <Icon icon="times" regular />
              ) : (
                <Icon icon="bars" regular />
              )}
            </button>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    Schedules
                    <i className="far fa-angle-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to="/schedules/kismet/">
                      Kismet
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/saltaire">
                      Saltaire
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/fair-harbor">
                      Fair Harbor
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/atlantique">
                      Atlantique
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/dunewood">
                      Dunewood
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/ocean-beach">
                      Ocean Beach
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/seaview">
                      Seaview
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/schedules/ocean-bay-park">
                      Ocean Bay Park
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    Info
                    <i className="far fa-angle-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to="/updates/">
                      Updates
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/info/fares/">
                      Fares
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/info/parking/">
                      Parking
                    </DropdownItem>
                    <DropdownItem
                      href="https://seasonparking.fireislandferries.com/"
                      target="_blank"
                    >
                      Season Parking
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/communities/">
                      Communities
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to="/info/deals/">
                      Deals
                    </DropdownItem> */}
                    {/* <DropdownItem tag={Link} to="/events/">
                      Events
                    </DropdownItem> */}
                    <DropdownItem tag={Link} to="/info/contact/">
                      Contact
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to="/info/weddings/">
                      Weddings
                    </DropdownItem> */}
                    <DropdownItem tag={Link} to="/info/charters/">
                      Charters
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/info/faq/">
                      FAQ
                    </DropdownItem>
                    <DropdownItem
                      href="https://www.fimtickets.com/"
                      target="_blank"
                    >
                      mTickets
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem header>Freight</DropdownItem>
                    <DropdownItem tag={Link} to="/info/freight/">
                      Freight Info
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/info/freight/faq">
                      Freight FAQ
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to="/info/freight/ldw">
                      Labor Day Weekend
                    </DropdownItem> */}

                    <DropdownItem tag={Link} to="/info/freight/hours">
                      Freight House Hours
                    </DropdownItem>
                    <DropdownItem
                      href="https://fireislandfreight.azurewebsites.net/"
                      target="_blank"
                    >
                      Freight Account Portal
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem header>Our Sites</DropdownItem>
                    <DropdownItem
                      href="https://shop.fireislandferries.com"
                      target="_blank"
                    >
                      Shop/Apparel
                    </DropdownItem>
                    {/* <DropdownItem
                      className='frt-link'
                      href='http://www.fimtickets.com'
                      target='_blank'
                    >
                      Freight
                    </DropdownItem> */}
                    <DropdownItem
                      className="wt-link"
                      href="http://www.fireislandwatertaxi.com"
                      target="_blank"
                    >
                      Water Taxi
                    </DropdownItem>
                    <DropdownItem
                      className="wt-link"
                      href="http://www.shorelineli.com"
                      target="_blank"
                    >
                      Shoreline Trading Co.
                    </DropdownItem>
                    <DropdownItem
                      className="wt-link"
                      href="http://www.fimarineservices.com"
                      target="_blank"
                    >
                      F.I. Marine Services
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem>
                  <NavLink tag={Link} to='/communities/'>
                    Communities
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink tag={Link} to="/directory/">
                    Directory
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </Headroom>
    );
  }
}
