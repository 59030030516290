import React from 'react';

import './style.scss';

const RightArrow = ({ nextSlide }) => {
  return (
    <div
      className="carousel-arrow next-arrow"
      onClick={nextSlide}
      onKeyPress={nextSlide}
      role="button"
    >
      <i className="far fa-chevron-right" aria-hidden="true" />
    </div>
  );
};

const LeftArrow = ({ previousSlide }) => {
  return (
    <div
      className="carousel-arrow back-arrow"
      onClick={previousSlide}
      onKeyPress={previousSlide}
      role="button"
    >
      <i className="far fa-chevron-left" aria-hidden="true" />
    </div>
  );
};

let interval = 5000; // 5 seconds per slide

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
    };
  }
  componentDidMount = () => {
    if (this.props.children && this.props.children.length > 0) {
      this.startInterval();
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  startInterval = () => {
    this.timer = setInterval(() => {
      this.nextSlide();
    }, interval);
  };

  resetInterval = () => {
    clearInterval(this.timer);
    this.startInterval();
  };

  previousSlide = () => {
    this.resetInterval();
    if (this.state.activeSlide === 0) {
      return this.setState({ activeSlide: this.props.children.length - 1 });
    }
    this.setState((prevState) => ({
      activeSlide: prevState.activeSlide - 1,
    }));
  };

  nextSlide = () => {
    this.resetInterval();
    if (this.state.activeSlide === this.props.children.length - 1) {
      return this.setState({ activeSlide: 0 });
    }
    this.setState((prevState) => ({
      activeSlide: prevState.activeSlide + 1,
    }));
  };

  slideWidth = () => document.querySelector('.slide').clientWidth;

  render() {
    return (
      <div className="container carousel">
        <div className="carousel-slider shadow">
          {React.Children.map(this.props.children, (child, index) => (
            <div
              className={`slider-item ${
                this.state.activeSlide === index
                  ? 'visible-slide'
                  : 'hidden-slide'
              }`}
            >
              {child}
            </div>
          ))}
          {this.props.children.length > 0 && (
            <div>
              <LeftArrow previousSlide={this.previousSlide} />
              <RightArrow nextSlide={this.nextSlide} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
