import React from 'react';
import { Link } from '@reach/router';
import { withRouteData } from 'react-static';
import Helmet from 'react-helmet';
import { compose } from 'recompose';

import withTracker from '../utils/withTracker';

import AppInfo from '../components/AppInfo';
import BeachText from '../components/BeachText';
import Waves from '../components/Waves';
import Carousel from '../components/Carousel/Carousel';
import Slide from '../components/Carousel/Slide';
import QuickSchedule from '../components/QuickSchedule';
import Alerts from '../components/Alerts';
import Navigation from '../components/Navigation';

// import mTicketSplash from '../assets/img/mtix-mockup.jpg';
// import maskSplash from '../assets/img/mask.jpg';
import kidSplash from '../assets/img/beaches/ocean-beach.jpg';
import kismetSplash from '../assets/img/beach-splash.jpg';
import shorelineSplash from '../assets/img/Shoreline-14.jpg';
import wtSplash from '../assets/img/courier.jpg';
import ShopInfo from '../components/ShopInfo';

const largeH1 = 'calc(30px + (50 - 30) * ((100vw - 300px) / (1600 - 300)))';
const largeH2 = 'calc(15px + (25 - 15) * ((100vw - 300px) / (1600 - 300)))';

export default compose(withRouteData)(({ scheduleCalendar, posts, events }) => (
  <div>
    <Helmet
      title="Fire Island Ferries"
      meta={[
        {
          name: 'description',
          content:
            'Fire Island Ferries provides ferry service between Bay Shore, NY and Kismet, Saltaire, Fair Harbor, Dunewood, Atlantique, Ocean Beach, Seaview, and Ocean Bay Park on Fire Island.  Visit our site for updates, service notifications, and new schedules.',
        },
        {
          name: 'keywords',
          content: 'Fire Island Ferries, Water Taxi, notifications, updates',
        },
        {
          name: 'robots',
          content: 'nosnippet',
        },
      ]}
    />
    <Navigation />
    <Carousel>
      {/* Temporary Coronavirus Update Slide */}
      {/* <Slide>
          <div className='row h-100 justify-content-center align-items-end text-center'>
            <div className='col-sm-10'></div>
          </div>
        </Slide> */}
      {/* Slide 1 */}
      {/* <Slide>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-sm-10">
              <BeachText mode="dark" rotate />
            </div>
          </div>
        </Slide> */}
      {/* Slide 2 */}
      <Slide>
        <div className="row h-100 justify-content-center align-items-start">
          <div className="col-12 text-center">
            {/* <h1>Let's summer!</h1>
            <h2>Come Visit Beautiful Fire Island Beaches Today</h2> */}
            {/* <Link className="btn btn-light mt-2" to="/schedules">
                Click Here for Schedules
              </Link> */}
          </div>
        </div>
      </Slide>
      <Slide image={shorelineSplash} overlayOverride>
        <div className="row h-100 justify-content-center align-items-start">
          <div className="col-12 text-center">
            <a href="https://www.shorelineli.com" target="_blank">
              <h1>Great South Bay Cruises</h1>
              <h2 className="px-md-5 d-none d-sm-block">
                Check out Shoreline Trading Company and enjoy a bay cruise
                aboard Running Wild, a 46 passenger cruising yacht.
              </h2>
              <a
                href="https://www.shorelineli.com"
                target="_blank"
                className="btn btn-outline-light mt-2"
              >
                Book Now
              </a>
            </a>
            {/* <Link className="btn btn-light mt-2" to="/communities">
                Community Info
              </Link> */}
          </div>
        </div>
      </Slide>
      <Slide image={wtSplash} overlayOverride>
        <div className="row h-100 justify-content-center align-items-start">
          <div className="col-12 text-center">
            <a href="https://www.fireislandwatertaxi.com" target="_blank">
              <h1>Expand the Adventure</h1>
              <h2 className="px-md-5 d-none d-sm-block">
                Expand your Fire Island adventure and enjoy dinner in Ocean
                Beach or a walking tour of the Sunken Forest. The fastest way to
                travel between communities.
              </h2>
            </a>
            {/* <Link className="btn btn-light mt-2" to="/communities">
                Community Info
              </Link> */}
          </div>
        </div>
      </Slide>

      <Slide image={kidSplash}>
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-12 text-center">
            <Link to="/communities">
              <h1>#weLoveFINY</h1>
            </Link>
            {/* <h2>
              Check out the beautiful communities we serve on Fire Island!
            </h2> */}
            {/* <Link className="btn btn-light mt-2" to="/communities">
                Community Info
              </Link> */}
          </div>
        </div>
      </Slide>
      {/* Slide 2 */}
      {/* <Slide image={maskSplash}>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-sm-10 text-right">
              <h1>Face coverings are always required.</h1>
              <h2>
                As per NYS executive order, masks or face coverings are required
                around the terminal and aboard our vessels.
              </h2>
              <Link
                className="btn btn-outline-light"
                to="/updates/coronavirus-update"
              >
                More Info
              </Link>
            </div>
          </div>
        </Slide> */}
      {/* <Slide image={mTicketSplash}>
          <div className='row h-100 align-items-center'>
            <div className='col-sm-8'>
              <h1>FI mTickets</h1>
              <h2>
                Stop fumbling for cash, download the mTickets app now to buy
                your Ferry and Water Taxi tickets!
              </h2>
              <a
                className='btn btn-light mt-2'
                href='http://www.fimtickets.com'
                target='_blank'
              >
                Download Now <i className='far fa-arrow-down' />
              </a>
            </div>
          </div>
        </Slide> */}
    </Carousel>
    <div className="wave-schedule">
      <div className="wave-index shadowed-alpha">
        <Waves>
          <div
            className="text-center"
            style={{ color: 'white', padding: '1em 1em 0 1em' }}
          >
            <h1
              style={{
                fontFamily: '"Nunito",Helvetica, Arial, sans-serif',
                fontSize: '1.9em',
              }}
            >
              Let’s Ride into Summer Together!
            </h1>
            <p style={{ fontSize: '1.1em' }} className="mb-2">
              A short ferry ride to miles of beautiful beaches & fresh air, food
              & shopping, and endless summer memories on Fire Island!
            </p>
            <p style={{ fontSize: '1.1em' }} className="mb-2">
              Please take a moment to prepare for your visit & read up on our
              most frequently asked questions below.
            </p>
            {/* <Link
              to="/updates/coronavirus-update"
              className="btn btn-outline-light mt-2"
            >
              Our Covid Response
            </Link>{" "} */}
            <Link to="/info/faq" className="btn btn-outline-light mt-2">
              FAQ
            </Link>{' '}
            <Link
              to="/info/planning-your-trip"
              className="btn btn-outline-light mt-2"
            >
              Planning Your Trip
            </Link>
          </div>

          {/* <QuickSchedule calendar={scheduleCalendar} /> */}
        </Waves>
      </div>
    </div>
    {posts && posts.length > 0 && <Alerts posts={posts} />}
    {/* <div>
      <section id="alerts">
        <div className="container">
          <div className="row h-100">
            <div className={`fiapi col-md post-info`}>
              <h3>Freight Update</h3>
              <h2>
                <Link to="info/freight/ldw">
                  2023 Labor Day Weekend Freight Info
                </Link>
              </h2>
              <p className="timestamp">Posted on: August 19, 2023</p>
              <p>
                Please review our special information and freight house hours
                for Labor Day Weekend 2023.{' '}
              </p>
              <Link
                className={`btn btn-outline-primary align-bottom stretched-link`}
                to="info/freight/ldw"
              >
                More Info
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div> */}
    <div style={{ textAlign: 'center' }}>
      <AppInfo />
    </div>
    <div style={{ textAlign: 'center' }}>
      <ShopInfo />
    </div>
  </div>
));
