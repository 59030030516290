import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { Link } from '@reach/router';
import moment from 'moment';
import { uniqBy } from 'lodash';
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import TimeHelper from '../../utils/timeHelper';
import TooltipItem from '../TooltipItem';

import './style.scss';

const gateNote = {
  1: {
    key: 'B',
    FI: 'Returns to Main Terminal (Gate 1) in Bay Shore at all times.',
    BS: 'Leaves from Main Terminal (Gate 1) in Bay Shore at all times.',
  },
  2: {
    key: 'W',
    FI: 'Returns to West Terminal (Gate 2) in Bay Shore at all times.',
    BS: 'Leaves from West Terminal (Gate 2) in Bay Shore at all times.',
  },
  3: {
    key: 'S',
    FI: 'Returns to Saltaire Terminal (Gate 3) in Bay Shore at all times.',
    BS: 'Leaves from Saltaire Terminal (Gate 3) in Bay Shore at all times.',
  },
};

export default class QuickSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.beaches = [
      'Kismet',
      'Saltaire',
      'Fair Harbor',
      'Atlantique',
      'Dunewood',
      'Ocean Beach',
      'Seaview',
      'Ocean Bay Park',
    ];
    this.state = {
      beach: 'Ocean Beach',
      trips: {
        link: '',
        available: false,
        beach: '',
        date: '',
        departing: {
          BS: [],
          FI: [],
        },
      },
      quickDate: {
        value: moment().toDate(),
        short: moment().format('YYYY-MM-DD'),
        text: moment().format('[Today], MMMM Do'),
      },
      loading: true,
    };
    this.state.times = [];
    if (
      props.calendar &&
      props.calendar[this.state.beach] &&
      props.calendar[this.state.beach]
    ) {
      this.state.times =
        props.calendar[this.state.beach][this.state.quickDate.short];
    }
    this.beachList = this.fillBeaches();
  }

  componentDidMount() {
    this.setSchedule();
  }

  onDateClick = date => {
    // this.setState({
    //   quickDate: {
    //     value: date,
    //     short: date.format('YYYY-MM-DD'),
    //     text: date.format(`${this.checkDate(date)}, MMMM Do`),
    //   },
    // }, () => {
    //   this.setSchedule();
    // });
    this.state.quickDate = {
      value: date,
      short: moment(date).format('YYYY-MM-DD'),
      text: moment(date).format(`${this.checkDate(date)}, MMMM Do`),
    };
    this.setSchedule();
  };

  onBeachClick(beach) {
    // this.setState({
    //   beach,
    // }, () => {
    //   this.setSchedule();
    // });
    this.state.beach = beach;
    this.setSchedule();
  }

  setSchedule = () => {
    if (
      this.props.calendar[this.state.beach] &&
      this.props.calendar[this.state.beach][this.state.quickDate.short] &&
      (this.props.calendar[this.state.beach][this.state.quickDate.short]
        .departing.BS.length ||
        this.props.calendar[this.state.beach][this.state.quickDate.short]
          .departing.FI.length)
    ) {
      this.setState({
        trips: {
          link: this.props.calendar[this.state.beach].link,
          available: true,
          beach: this.state.beach,
          date: this.state.quickDate.text,
          dateRef: this.state.quickDate.value,
          departing: this.props.calendar[this.state.beach][
            this.state.quickDate.short
          ].departing,
        },
      });
    } else {
      this.setState({
        trips: {
          link: '',
          available: false,
          beach: this.state.beach,
        },
        loading: false,
      });
    }
  };

  fillBeaches = () => {
    return this.beaches.map(beach => {
      const boundItemClick = this.onBeachClick.bind(this, beach);
      return (
        <DropdownItem key={beach} onClick={boundItemClick}>
          {beach}
        </DropdownItem>
      );
    });
  };

  checkDate = date => {
    const daysDiff = moment()
      .startOf('day')
      .diff(date, 'days');
    if (daysDiff === 0) {
      return '[Today]';
    } else if (daysDiff === -1) {
      return '[Tomorrow]';
    }
    return 'dddd';
  };

  render() {
    return (
      <div>
        <div className="quick-schedule shadowed">
          <div className="row m-0 align-items-center justify-content-md-center">
            <div className="col-sm-12 col-md-auto text-center">
              <h3>
                <i className="far fa-calendar-alt" /> Quick Schedules
              </h3>
            </div>
            <div className="col-sm-6 col-md-auto p-1">
              <UncontrolledDropdown>
                <DropdownToggle color="primary" outline className="btn-block">
                  {this.state.beach}{' '}
                  <i className="fal fa-angle-down fa-lg ml-2" />
                </DropdownToggle>
                <DropdownMenu>{this.beachList}</DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="col-sm-6 col-md-auto p-1">
              <DatePicker
                customInput={
                  <DatePickerButton text={this.state.quickDate.text} />
                }
                selected={this.state.quickDate.value}
                calendarClassName="ferry-calendar"
                onChange={this.onDateClick}
                minDate={new Date()}
                popperPlacement="top"
              />
            </div>
          </div>
        </div>
        {this.state.trips.available && (
          <div className="row m-0">
            <div className="col-sm-12 col-md-6 col-margin">
              <QuickSchedulePanel
                departing="BS"
                from="Bay Shore"
                link={this.state.trips.link}
                to={this.state.trips.beach}
                trips={this.state.trips.departing.BS}
                date={this.state.trips.date}
                dateRef={this.state.trips.dateRef}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <QuickSchedulePanel
                departing="FI"
                link={this.state.trips.link}
                from={this.state.trips.beach}
                to="Bay Shore"
                trips={this.state.trips.departing.FI}
                date={this.state.trips.date}
                dateRef={this.state.trips.dateRef}
              />
            </div>
          </div>
        )}
        <Link
          className="btn btn-light d-block d-lg-none mx-3 mt-3 border-8"
          to={this.state.trips.link}
        >
          Full Schedule <i className="far fa-clock" />
        </Link>
        {!this.state.loading && !this.state.trips.available && (
          <div className="text-center no-trips-warning">
            <h3 className="no-trips">
              There are no trips available for that date and beach combination.
            </h3>
          </div>
        )}
        {this.state.loading && !this.state.trips.available && (
          <div className="text-center">
            <h3 className="white-text">
              <i className="fas fa-spinner fa-spin" /> Loading
            </h3>
          </div>
        )}
      </div>
    );
  }
}

class DatePickerButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button
        className="btn btn-outline-primary btn-block"
        onClick={this.props.onClick}
      >
        {this.props.text}{' '}
        <i className="fal fa-angle-down fa-lg" data-fa-transform="right-4" />
      </button>
    );
  }
}

const QuickSchedulePanel = props => {
  const { from, to, trips, date, dateRef, link, departing } = props;
  let notes = [];
  const today =
    moment()
      .startOf('day')
      .diff(dateRef, 'days') === 0;
  return (
    <div className="schedule-well shadowed">
      <h2>
        {from} <i className="far fa-angle-double-right text-accent" /> {to}
      </h2>
      <Link
        className="btn btn-outline-secondary btn-sm btn-top-right d-none d-lg-block"
        to={link}
      >
        Full Schedule
      </Link>
      <p className="date">{date}</p>
      <div className="trip-times">
        {trips.map(trip => (
          <span key={`${trip.time}${departing}`}>
            <span
              className={`trip ${trip.peak ? 'peak' : 'standard'} ${
                today && TimeHelper.timePassed(trip.time)
                  ? 'passed'
                  : 'upcoming'
              }`}
            >
              {trip.gate &&
                notes.push({
                  note: gateNote[trip.gate].key || trip.gate,
                  noteText: gateNote[trip.gate][departing],
                }) && (
                  <TooltipItem
                    id={`${trip.time.replace(/:/g, '')}${departing}`}
                    text={gateNote[trip.gate][departing]}
                  >
                    <span className="badge badge-primary gate">
                      {gateNote[trip.gate].key || trip.gate}
                    </span>
                  </TooltipItem>
                )}
              {trip.note &&
                notes.push({ note: trip.note, noteText: trip.noteText }) && (
                  <TooltipItem
                    id={`${trip.time.replace(/:/g, '')}${departing}${
                      trip.note
                    }`}
                    text={trip.noteText}
                  >
                    <span className="badge badge-primary gate">
                      {trip.note}
                    </span>
                  </TooltipItem>
                )}
              {TimeHelper.getFormattedTime(trip.time)}
            </span>
          </span>
        ))}
      </div>
      {notes.length > 0 && (
        <div>
          <strong>Note</strong>
          <br />
          {uniqBy(notes, 'note').map(item => (
            <div key={`${item.note}${departing}`}>
              <span className="badge badge-primary gate">{item.note}</span>{' '}
              {item.noteText}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
