import React from 'react';

import defaultImage from './FirebirdPort.jpg';

const Slide = ({ image, children, overlayOverride = false, dark = false }) => {
  return (
    <div
      className="slide"
      style={{
        backgroundImage: `url(${image || defaultImage})`,
      }}
    >
      {!overlayOverride && !dark && <div className="hero-overlay" />}
      {dark && !overlayOverride && <div className="hero-overlay-dark" />}
      {children}
    </div>
  );
};

export default Slide;
