let _gaInitialised = false;

function getInitialised() {
  return _gaInitialised;
}

function setInitialised() {
  _gaInitialised = true;
}

module.exports = {
  getInitialised,
  setInitialised,
};
