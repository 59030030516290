import React from "react";

import "./style.scss";

import "../../assets/img/Waves.png";

const Waves = ({ children }) => (
  <div className="wave-container d-print-none">
    <div className="wave-topper" />
    <div className="wave-content">
      <div className="content">{children}</div>
      <div className="wave-texture" />
    </div>
  </div>
);

export default Waves;
