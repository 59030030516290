import React from 'react';
import { Link } from '@reach/router';

import './style.scss';

import teeShirt from './ferryTee.png';

const ShopInfo = () => (
  <div className="shopinfo">
    <div className="pattern-background" />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md text-center text-md-right mb-4">
          <a href="https://shop.fireislandferries.com" target="_blank">
            <h1>Shop Our Gear</h1>
          </a>
          <h2>Fire Island Ferries Apparel Now Available.</h2>
          <a
            href="https://shop.fireislandferries.com"
            target="_blank"
            className="btn btn-outline-primary mt-2"
          >
            Shop Now
          </a>
        </div>
        <div className="col-md-4 text-center">
          <img src={teeShirt} className="shopmockup" alt="FI Ferries T-Shirt" />
        </div>
      </div>
    </div>
  </div>
);

export default ShopInfo;
