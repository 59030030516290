import React from 'react';
import GoogleAnalytics from 'react-ga';
import gaService from './ga';

const withTracker = (WrappedComponent) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({ page });
    GoogleAnalytics.pageview(page);
  };

  const HOC = (props) => {
    if (typeof window !== 'undefined') {
      if (true) {
        if (!gaService.getInitialised()) {
          gaService.setInitialised();
          GoogleAnalytics.initialize('UA-21784898-5', {
            debug: true,
          });
        }
        console.log(props.history);
        if (props.history && props.history.location) {
          const page = props.history.location.pathname;
          trackPage(page);
        }
      }
    }
    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
