import React from 'react';
import { Link } from '@reach/router';
import moment from 'moment';

import './style.scss';

const Alerts = ({ posts }) => (
  <section id="alerts">
    <div className="container">
      <div className="row h-100">
        {posts.slice(0, 3).map((post) => (
          <div
            key={post.slug}
            className={`fiapi ${
              posts.length >= 3 ? 'col-md-4' : 'col-md'
            } post-${post.level.toLowerCase()}`}
          >
            <h3>{post.tags.join(', ')}</h3>
            <h2>
              <Link to={`/updates/${post.slug}`}>{post.title}</Link>
            </h2>
            <p className="timestamp">
              Posted on: {moment(post.publishDate).format('MMMM D, YYYY')}
            </p>
            <p>{post.excerpt}</p>
            <Link
              className={`btn ${
                post.level === 'Danger' || post.level === 'Warning'
                  ? 'btn-outline-danger'
                  : 'btn-outline-primary'
              } align-bottom`}
              to={`/updates/${post.slug}`}
            >
              More Info
            </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Alerts;
