import React from 'react';
import { Link } from '@reach/router';

import './style.scss';

import mTicketsMockup from './FImTicketsMockup.png';
import appleStore from './applestore.png';
import playStore from './googleplay.png';

const AppInfo = () => (
  <div className="mTickets">
    <div className="pattern-background" />
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-4 text-center">
          <img
            src={mTicketsMockup}
            className="mticketsmockup"
            alt="FI mTickets"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <a href="https://www.fimtickets.com" target="_blank">
            <h1>FI mTickets</h1>
          </a>
          <h2>Buy your ferry and water taxi tickets anywhere, anytime.</h2>
          <div className="row instructions">
            <div className="instruction col-sm">
              <h3>1</h3>
              <h4>Download</h4>
              <p>the free iOS or Android app</p>
            </div>
            <div className="instruction col-sm">
              <h3>2</h3>
              <h4>Pay</h4>
              <p>using a major credit card</p>
            </div>
            <div className="instruction col-sm">
              <h3>3</h3>
              <h4>Activate</h4>
              <p>just before boarding the vessel</p>
            </div>
          </div>
          <div className="store-icons">
            <a
              href="https://itunes.apple.com/us/app/fire-island-mtickets/id1240275646?mt=8"
              target="_blank"
            >
              <img
                src={appleStore}
                alt="Downlod on the Apple App Store"
                className="store-icon"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.justride.fif"
              target="_blank"
            >
              <img
                src={playStore}
                alt="Download on the Google Play Store"
                className="store-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AppInfo;
